<template>
  <div class="d-flex flex-column secondary-lighten pb-2">
    <div
      class="white d-flex align-start rounded flex-column px-4 px-sm-5 pt-5 pb-1"
    >
      <div
        class="d-flex flex-column-reverse flex-sm-row align-center align-sm-start justify-space-between global-full-width"
      >
        <div class="global-full-width">
          <div
            class="d-flex flex-column align-start flex-md-row flex-sm-grow-1 global-full-width pr-sm-6 pr-md-0 mb-2"
          >
            <v-sheet :width="breakpoint.smAndDown ? '100%' : '225px'" class="flex-grow-1 flex-shrink-0 mr-2 ">
              <c-text-field
                :value="filters.commonSearch"
                solo
                flat
                :placeholder="$t('campaign.enter_name')"
                hide-details
                background-color="white"
                border-color="secondary"
                color="red"
                :height="isMobile ? 46 : 30"
                @input="$emit('set-filters', { name: 'commonSearch', event: $event })"
              >
                <template #prepend-inner>
                  <v-icon :color="isMobile ? 'black' : 'secondary-darken'" :size="16">
                    $magnifier
                  </v-icon>
                </template>
              </c-text-field>
            </v-sheet>
            <div class="d-flex flex-column flex-sm-row global-full-width mt-sm-3 mt-md-0">
              <campaign-new-filters
                :filters="filters"
                :is-loading="isLoading"
                class="mt-2 mt-sm-0"
                @set-filters="$emit('set-filters', $event)"
              />
              <v-row no-gutters class="mt-3 mt-sm-0 justify-space-between">
                <v-col :cols="isAbTest ? 7 : 12">
                  <v-badge
                    color="primary"
                    overlap
                    :content="activeFilters.length"
                    :value="activeFilters.length"
                    :offset-x="isMobile ? 32 : 12"
                    :offset-y="isMobile ? 28 : 9"
                    class="d-block d-sm-inline-block"
                  >
                    <c-btn
                      icon
                      :small="!isMobile"
                      :block="isMobile"
                      :height="!isMobile ? 30 : ''"
                      :width="!isMobile ? 30 : ''"
                      outlined
                      class="rounded"
                      color="primary"
                      :icon-props="{
                        icon: '$filters',
                        size: '16',
                        color: 'black'
                      }"
                      @click="activeFilters.length === 0 ? toggleFormFilter() : toggleWithFiltersForm()"
                    />
                  </v-badge>
                </v-col>
                <v-col v-if="isMobile && isAbTest" cols="auto">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <c-btn
                        v-bind="attrs"
                        icon
                        small
                        class="secondary-lighten rounded"
                        :width="isMobile ? 36 : 26"
                        :height="isMobile ? 36 : 26"
                        :loading="isLoading"
                        :icon-props="{
                          icon: '$update',
                          size: isMobile ? '19' : '14',
                          color: 'primary'
                        }"
                        v-on="on"
                        @click="action('fetch', GTM_EVENTS.CAMPAIGNS_ACTION_REFRESH)"
                      />
                    </template>
                    <span>{{ $t('campaign.refresh') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <c-btn
                        v-bind="attrs"
                        icon
                        small
                        :width="isMobile ? 36 : 26"
                        :height="isMobile ? 36 : 26"
                        class="secondary-lighten rounded ml-1"
                        :loading="isLoading"
                        :icon-props="{
                          icon: '$reset-filters',
                          size: isMobile ? '19' : '14',
                          color: 'error'
                        }"
                        v-on="on"
                        @click="action('reset', GTM_EVENTS.CAMPAIGNS_ACTION_RESET)"
                      />
                    </template>
                    <span>{{ $t('campaign.reset') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="isShownFilterForm || activeFilters.length" class="d-flex flex-wrap mt-4 mt-sm-3 mt-md-4">
            <selected-campaign-filters
              v-if="activeFilters.length > 0"
              :active-filters="activeFilters"
              :filter-list="filterList"
              :is-loading="isLoading"
              :can-adding-filter="canAddingFilter"
              :show-form-filter="showFormFilters"
              @remove="$emit('remove-filter', $event)"
              @edit-filter="setEditingFilter($event)"
              @add="openFormFilter()"
              @set-filters="setFilterList($event)"
            />
            <campaign-filters-form
              v-if="isShownFilterForm"
              class="mt-3 mt-sm-0"
              :is-loading="isLoading"
              :filter-list="totalFilters"
              @set-filters="setFilterList($event)"
            />
          </div>
        </div>
        <div v-if="!isAbTest || !isMobile" class="d-flex flex-sm-nowrap align-center mb-5 mb-sm-0">
          <div class="mr-1">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <c-btn
                  v-bind="attrs"
                  icon
                  class="secondary-lighten rounded"
                  :width="isMobile ? 36 : 26"
                  :height="isMobile ? 36 : 26"
                  :loading="isLoading"
                  :icon-props="{
                    icon: '$update',
                    size: isMobile ? '19' : '14',
                    color: 'primary'
                  }"
                  v-on="on"
                  @click="action('fetch', GTM_EVENTS.CAMPAIGNS_ACTION_REFRESH)"
                />
              </template>
              <span>{{ $t('campaign.refresh') }}</span>
            </v-tooltip>
          </div>
          <div class="mr-1">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <c-btn
                  v-bind="attrs"
                  icon
                  :width="isMobile ? 36 : 26"
                  :height="isMobile ? 36 : 26"
                  class="secondary-lighten rounded"
                  :loading="isLoading"
                  :icon-props="{
                    icon: '$reset-filters',
                    size: isMobile ? '19' : '14',
                    color: 'error'
                  }"
                  v-on="on"
                  @click="action('reset', GTM_EVENTS.CAMPAIGNS_ACTION_RESET)"
                />
              </template>
              <span>{{ $t('campaign.reset') }}</span>
            </v-tooltip>
          </div>
          <shown-headers
            v-if="!isMobile"
            :headers="headers"
            local-key="campaign"
            @headers-updated="$emit('headers-updated', $event)"
          />
        </div>
      </div>
      <sorting-field
        v-if="isMobile"
        class="pb-3 mt-3 global-full-width"
        :is-loading="isLoading"
        :options="options"
        @change-sort-by="$emit('change-sort-by', $event)"
        @change-sort-desc="$emit('change-sort-desc', $event)"
      />
    </div>
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import CampaignNewFilters from '@/components/Campaign/CampaignNewFilters.vue'
  import ShownHeaders from '@/components/ShownHeaders.vue'
  import CampaignFiltersForm from '@/components/Campaign/CampaignFilters/CampaignFiltersForm.vue'
  import SelectedCampaignFilters from '@/components/Campaign/CampaignFilters/SelectedCampaignFilters.vue'
  import gtmPush from '@/services/utils/gtm-push.js'
  import SortingField from '@/components/SortingField.vue'

  export default {
    name: 'CampaignHeaderActions',
    components: {
      SortingField,
      SelectedCampaignFilters,
      CBtn,
      CTextField,
      CampaignFiltersForm,
      ShownHeaders,
      CampaignNewFilters
    },
    props: {
      filters: {
        type: Object,
        required: true
      },
      headers: {
        type: Array,
        required: true
      },
      options: {
        type: Object,
        required: true
      },
      filterList: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showFormFilters: false,
        isEditFilter: false,
        GTM_EVENTS,
        isAbTest: !!localStorage.ab_test_campaings_actions
      }
    },
    computed: {
      breakpoint() {
        return this.$vuetify.breakpoint
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      activeFilters() {
        return this.filterList.filter((filter) => filter.value)
      },
      totalFilters() {
        return this.filterList.filter((filter) => !filter.value)
      },
      isShownFilterForm() {
        return !this.isEditFilter && this.showFormFilters
      },
      canAddingFilter() {
        return this.totalFilters.length > 0
      }
    },
    methods: {
      action(emitValue, event) {
        this.$emit(emitValue)
        gtmPush({ event })
      },
      openFormFilter() {
        this.showFormFilters = true
      },
      toggleFormFilter() {
        this.showFormFilters = !this.showFormFilters
      },
      toggleWithFiltersForm() {
        this.showFormFilters = false
      },
      setEditingFilter(data) {
        this.isEditFilter = data
      },
      setFilterList(data) {
        this.$emit('set-filter-list', data)
        this.showFormFilters = false
      }
    }
  }
</script>
